import React, { useState } from 'react';
import PropTypes from "prop-types";
import { Dialog, DialogContent, IconButton } from '@mui/material';
import {styled} from "@mui/material/styles";
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';

import {ModalContext} from "@/contexts/ModalContext.jsx";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const ModalProvider = ({ children }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [modalMaxWidth, setModalMaxWidth] = useState('xs');

    const openModal = (content, maxWidth = 'xs') => {
        setModalContent(content);
        setModalMaxWidth(maxWidth);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    return (
        <ModalContext.Provider value={{ openModal, closeModal }}>
            {children}
            <Dialog TransitionComponent={Transition}
                    keepMounted
                    maxWidth={modalMaxWidth}
                    open={modalOpen}
                    onClose={closeModal}>
                <CloseButton>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={closeModal}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </CloseButton>
                <DialogContent>
                    {modalContent}
                </DialogContent>
            </Dialog>
        </ModalContext.Provider>
    );
};

ModalProvider.propTypes = {
    children: PropTypes.any,
};

const CloseButton = styled('div')({
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 1,
});
