export const COLOR_BLACK = '#000000';
export const COLOR_DARK_GREY = '#808080';
export const COLOR_GREY = '#b3b3b3';
export const COLOR_LIGHT_GREY = '#f2f2f2';
export const COLOR_WHITE = '#fff';
export const COLOR_BLUE = '#3A9CE4';
export const COLOR_BLUE_SECONDARY = '#348FD3';
export const COLOR_RED = '#FF4329';
export const COLOR_RED_SECONDARY = '#EE3A22';
export const COLOR_GREEN = '#40820D';
export const COLOR_GREEN_SECONDARY = '#33670A';
export const COLOR_LIGHTEST_GREEN = '#F9FFF4';

export const COLOR_INPUT_BORDER = '#e5e5e5';
