import {createTheme} from "@mui/material";
import {
    COLOR_BLACK,
    COLOR_BLUE, COLOR_BLUE_SECONDARY, COLOR_GREY, COLOR_LIGHT_GREY,
    COLOR_WHITE
} from "@/themes/colors.jsx";

const defaultTheme = createTheme({
    typography: {
        allVariants: {fontFamily: 'var(--global-font-family)'},
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    minWidth: 'auto',
                    display: 'inline-block',
                    textTransform: 'none',
                    boxShadow: 'none',
                    relative: 'relative',
                    whiteSpace: 'nowrap',
                },
                outlined: {
                    color: COLOR_WHITE,
                    backgroundColor: COLOR_BLUE,
                    border: `1px solid ${COLOR_BLUE}`,
                    fontFamily: 'var(--global-font-family)',
                    fontWeight: 700,
                    transition: 'background-color 0.3s linear',
                    '&:hover': {
                        backgroundColor: COLOR_BLUE_SECONDARY,
                        borderColor: COLOR_BLUE_SECONDARY,
                        boxShadow: 'none',
                    }
                },
                contained: {
                    color: COLOR_WHITE,
                    backgroundColor: COLOR_BLUE,
                    border: `1px solid ${COLOR_BLUE}`,
                    fontFamily: 'var(--global-font-family)',
                    fontWeight: 700,
                    transition: 'background-color 0.3s linear',
                    '&:hover': {
                        backgroundColor: COLOR_BLUE_SECONDARY,
                        borderColor: COLOR_BLUE_SECONDARY,
                        boxShadow: 'none',
                    },
                    '&.Mui-disabled': {
                        color: COLOR_WHITE,
                        backgroundColor: COLOR_BLUE,
                        border: `1px solid ${COLOR_BLUE}`,
                        opacity: 0.3,
                    },
                },
                cancel: {
                    color: COLOR_BLACK,
                    backgroundColor: COLOR_LIGHT_GREY,
                    border: `1px solid ${COLOR_LIGHT_GREY}`,
                    fontFamily: 'var(--global-font-family)',
                    fontWeight: 700,
                    transition: 'background-color 0.3s linear',
                    '&:hover': {
                        borderColor: COLOR_GREY,
                        boxShadow: 'none',
                    },
                    '&.Mui-disabled': {
                        opacity: 0.3,
                    },
                },
                sizeMedium: {
                    padding: '13px 24px',
                    borderRadius: '22.5px',
                    fontSize: '19px',
                    lineHeight: '22px',
                    minWidth: '200px',
                },
            }
        },
        MuiLoadingButton: {
            styleOverrides: {
                loadingIndicator: {
                    top: 16,
                    color: COLOR_WHITE,
                },
            }
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    borderRadius: 25,
                },
            },
        },
    },
});

export default defaultTheme;
